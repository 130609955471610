import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Header from "../components/Header"

const About = ({location}) => (
  <Layout location={location}>
    <SEO title="About" />
    <Header />
    <section className="container page">
      <div className="row">
        <div className="col-12 text-center">
          <h1>About Us</h1>
          <p>Precision Dent Removal was established by owner/operator Scott Woods in 2005 with a single-minded goal “to do the best job possible at the very best price”.</p>
          <p>Prior to its operation, Scott has worked in the panel beating industry since 1987 and in 1989 Scott was awarded the Illawarra Apprentice of the Year Award.</p>
          <p>During Scott’s panel beating background, he has been fortunate to have worked in the three largest Panel Beating workshops in the Shoalhaven and has learned valuable trade skills from his mentors.  Scott has always maintained ethical practices of “Do it once, do it right” and “There is no right way to do a wrong thing”. Scott works by his motto each and everyday... for you, his valued customers.</p>
          <p>With Scott having a young family and strong community ties within the Shoalhaven, Scott understands that he cannot do anything less than the best and knows customer satisfaction is the backbone behind any small business’s success and future operation. Scott guarantees if he is not 100% satisfied with the job he executes for you, you don’t pay 100% of the quoted price.</p>
        </div>
      </div>
    </section>
  </Layout>
)

export default About